import styled from 'styled-components'

const styles = {}

styles.StyledTrendWrapper = styled.div`
  border-top: ${props => (props.isSuggestion ? '0.5rem solid #f6f6f6' : 0)};
  padding: 0.75rem 1rem 1rem 1rem;
`

styles.StyledHeading = styled.h2`
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  margin: 0 0 1rem 0;
`

styles.StyledContent = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  & span {
    margin-right: 0.5rem;
  }
`
styles.StyledLoaderHeading = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

styles.LoaderContent = styled.div`
  border-top: 0;
  padding: 1rem;
  padding-bottom: 0.5rem;
`

styles.CardWrap = styled.div`
  display: flex;
  width: ${props => (props.columnWidth === 72 ? `4.5rem` : `4rem`)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
styles.StyledCategory = styled.div`
  a {
    text-decoration: none;
  }
`

styles.CardTitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #333333;
  margin-top: 0.25rem;
`

styles.PlaceholderImage = styled.div`
  width: ${props => (props.columnWidth === 72 ? `4.5rem` : `4rem`)};
  height: ${props => (props.columnWidth === 72 ? `4.5rem` : `4rem`)};
  border-radius: 50%;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px #eaeaea solid;
`
styles.TruncateTextContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
`
styles.Image = styled.img`
  object-fit: cover;
  width: ${props => (props.columnWidth === 72 ? `4.5rem` : `4rem`)};
  height: ${props => (props.columnWidth === 72 ? `4.5rem` : `4rem`)};
  border-radius: 50%;
  border: 1px #eaeaea solid;
`
const VIRTUAL_GRID_CLASS = '.ReactVirtualized__Grid'

styles.ScrollContainer = styled.div`
  overflow-x: scroll;
  height: 7rem;

  ${VIRTUAL_GRID_CLASS} {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /*To hide scrollbar in firefox */
    -webkit-overflow-scrolling: touch;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
`
export default styles

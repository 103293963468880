import styled from 'styled-components'

const styles = {}

styles.StyledTrendWrapper = styled.div`
  border-top: ${props => (props.isSuggestion ? '0.5rem solid #f6f6f6' : 0)};
  padding: 1rem;
  padding-bottom: 0.5rem;
`

styles.StyledHeading = styled.h2`
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  margin: 0 0 1rem 0;
`

styles.StyledContent = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  & span {
    margin-right: 0.5rem;
  }
`
styles.StyledLoaderHeading = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

styles.LoaderContent = styled.div`
  border-top: 0;
  padding: 1rem;
  padding-bottom: 0.5rem;
`

export default styles
